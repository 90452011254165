import MyButton, { MyButtonLink } from 'components/MyButton/MyButton';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import SectionHeader from 'components/SectionHeader/SectionHeader';
import { ManufacturerOrder } from 'features/purchases/models/ManufacturerOrder';
import React from 'react';
import { formatDateRelative, formatDateTimeRelative } from 'utils/dateHelpers';
import { ManufacturerOrderStatusBadge } from '../ManufacturerOrderStatusBadge/ManufacturerOrderStatusBadge';
import './SupplierOrderSummarySection.scss';

export default function SupplierOrderSummarySection({
    order,
    detailPath,
    autoMargin,
}: {
    order: ManufacturerOrder;
    detailPath: string;
    autoMargin?: boolean;
}) {
    return (
        <div
            key={order.id}
            className="SupplierOrderSummarySection"
        >
            <SectionHeader
                className="SupplierOrderSummarySection__SectionHeader"
                title="Supplier order"
                titleContext={order.reference}
                autoMargin={autoMargin}
            />
            <div className="SupplierOrderSummarySection__Content">
                <PropertyContainer layout="table">
                    <PropertyDisplay
                        label="Status"
                        value={
                            <ManufacturerOrderStatusBadge
                                status={order.context.orderStatus}
                                size="large"
                            />
                        }
                    />
                    <PropertyDisplay
                        label="ETA"
                        value={
                            order.eta ? formatDateRelative(order.eta, { alwaysDate: true }) : null
                        }
                    />
                    <PropertyDisplay
                        label="Last updated"
                        value={formatDateTimeRelative(order.updatedAt ?? order.createdAt)}
                    />
                </PropertyContainer>

                <MyButton
                    label="More info"
                    buttonType="Hollow"
                    size="small"
                    href={detailPath}
                    LinkComponent={MyButtonLink}
                />
            </div>
        </div>
    );
}
