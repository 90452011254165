import { z } from 'zod';
import { ManufacturerOrderStatusSchema } from './ManufacturerOrderStatus';

export const ManufacturerOrderSummarySchema = z.object({
    id: z.number(),
    reference: z.string(),
    context: z.object({
        orderStatus: ManufacturerOrderStatusSchema,
    }),
});

export type ManufacturerOrderSummary = z.infer<typeof ManufacturerOrderSummarySchema>;
