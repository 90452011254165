import Icons from 'Icons';
import ArchivedBadge from 'components/ArchivedBadge/ArchivedBadge';
import MyButton from 'components/MyButton/MyButton';
import { MyMenuButtonItem } from 'components/MyMenuButton/MyMenuButton';
import MyMenuKebabButton from 'components/MyMenuKebabButton/MyMenuKebabButton';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import SectionHeader from 'components/SectionHeader/SectionHeader';
import OrderAttachments from 'features/orders/components/OrderAttachments/OrderAttachments';
import OrderUpdatesMessagePanel from 'features/orders/components/OrderUpdatesMessagePanel/OrderUpdatesMessagePanel';
import { useOrderPresentation } from 'features/orders/providers/OrderPresentation';
import PurchaseOrderStatus from 'features/purchases/enums/PurchaseOrderStatus';
import { PurchaseOrderDetail } from 'features/purchases/models/PurchaseOrderDetail';
import purchasesApi from 'features/purchases/purchases.api';
import { selectIsMultiSupplier, selectManufacturer } from 'features/settings/settings.slice';
import { useBreakpoints } from 'providers/Breakpoints';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import coalesceClassNames from 'utils/coalesceClassNames';
import PurchaseOrderCloneModal from '../PurchaseOrderCloneModal/PurchaseOrderCloneModal';
import PurchaseOrderEditModal from '../PurchaseOrderEditModal/PurchaseOrderEditModal';
import PurchaseOrderStatusBadge from '../PurchaseOrderStatusBadge/PurchaseOrderStatusBadge';
import SupplierOrderSummarySection from '../SupplierOrderSummarySection/SupplierOrderSummarySection';
import './PurchaseOrderDetailModal.scss';
import PurchaseOrderDetailModalFooter from './PurchaseOrderDetailModalFooter';
import PurchaseOrderProductList from './PurchaseOrderProductList';

export default function PurchaseOrderDetailModal({
    model,
    isLoading,
    isError,
    close,
}: {
    model?: PurchaseOrderDetail;
    isLoading?: boolean;
    isError?: boolean;
    close?: () => void;
}) {
    const [archiveMutation] = purchasesApi.usePurchaseOrderArchiveMutation();
    const [unarchiveMutation] = purchasesApi.usePurchaseOrderUnarchiveMutation();

    const dialogManager = useDialogManager();
    const breakpoints = useBreakpoints();

    const { topLevelPageTitle } = useOrderPresentation();

    const [showCloneModal, setShowCloneModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const isMultiSupplier = useAppSelector(selectIsMultiSupplier);
    const manufacturer = useAppSelector(selectManufacturer(model?.manufacturerId));

    const archive = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: `Archive order`,
            message: `Are you sure you want to archive this order?`,
            acceptLabel: 'Yes, archive now',
            acceptButtonType: 'Danger',
        });

        if (confirm && model) {
            const result = await dialogManager.showLoadingWhile(archiveMutation(model.id));
            if (!(result as any).error) {
                // close if successful
                close?.();
            }
        }
    }, [archiveMutation, close, dialogManager, model]);

    const unarchive = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: `Un-archive order`,
            message: `Are you sure you want to un-archive this order?`,
            acceptLabel: 'Yes, un-archive now',
            acceptButtonType: 'Primary',
        });

        if (confirm && model) {
            await dialogManager.showLoadingWhile(unarchiveMutation(model.id));
        }
    }, [unarchiveMutation, dialogManager, model]);

    const menuItems: (MyMenuButtonItem | false)[] = useMemo(
        () => [
            !model?.isArchived &&
                !breakpoints.isSmallUp && {
                    label: `Clone`,
                    IconLeft: Icons.Clone,
                    onClick: () => setShowCloneModal(true),
                },
            model?.isArchived === false && {
                label: `Archive order`,
                IconLeft: Icons.Archive,
                onClick: archive,
            },
            model?.isArchived === true && {
                label: `Un-archive order`,
                IconLeft: Icons.Undo,
                onClick: unarchive,
            },
        ],
        [archive, breakpoints.isSmallUp, model?.isArchived, unarchive],
    );

    return (
        <MyModal
            className="PurchaseOrderDetailModal"
            close={close}
            isLoading={isLoading}
            isError={isError}
            mobileTitle={topLevelPageTitle}
            header={
                <>
                    <PageHeader
                        className="PurchaseOrderDetailModal__PageHeader"
                        title="Order"
                        titleContext={
                            <div className="PurchaseOrderDetailModal__PageHeader__TitleContext">
                                <div className="PurchaseOrderDetailModal__PageHeader__Tuid">
                                    {model?.tuid}
                                </div>
                                <PurchaseOrderStatusBadge
                                    status={model?.status}
                                    size="medium"
                                />
                                {model?.isArchived === true && <ArchivedBadge size="large" />}
                            </div>
                        }
                    >
                        {model && (
                            <>
                                {breakpoints.isSmallUp && (
                                    <MyButton
                                        label={`Clone`}
                                        title="Create a copy of this order"
                                        IconLeft={Icons.Clone}
                                        buttonType="Hollow"
                                        onClick={() => setShowCloneModal(true)}
                                    />
                                )}
                                <MyMenuKebabButton menuItems={menuItems} />
                            </>
                        )}
                    </PageHeader>

                    {model?.context.pendingUpdates?.hasPendingUpdates && (
                        <OrderUpdatesMessagePanel
                            className="PurchaseOrderDetailModal__OrderUpdatesMessagePanel"
                            model={model}
                        />
                    )}
                </>
            }
        >
            {model && (
                <>
                    {/* Supplier orders (if submitted) */}
                    {model.status === PurchaseOrderStatus.Submitted && (
                        <SupplierOrders purchaseOrderId={model.id} />
                    )}

                    {/* Details */}
                    <SectionHeader
                        title="Details"
                        autoMargin={model.status === PurchaseOrderStatus.Submitted}
                    />
                    <div
                        className={coalesceClassNames(
                            'PurchaseOrderDetailModal__Details',
                            !model.isReadOnly && 'editable',
                        )}
                    >
                        <PropertyContainer layout="table">
                            {isMultiSupplier && (
                                <PropertyDisplay
                                    label="Supplier"
                                    value={manufacturer?.name}
                                />
                            )}

                            <PropertyDisplay
                                label="Sales rep"
                                value={model.salesRep}
                            />

                            <PropertyDisplay
                                label="Sidemark"
                                value={model.sidemark}
                                verticalAlign="top"
                            />

                            <PropertyDisplay
                                label="Notes"
                                verticalAlign="top"
                                value={model.notes}
                            />
                        </PropertyContainer>

                        {!model.isReadOnly && (
                            <MyButton
                                className="PurchaseOrderDetailModal__Details__EditButton"
                                label="Edit"
                                size="small"
                                buttonType="Hollow"
                                onClick={() => setShowEditModal(true)}
                            />
                        )}
                    </div>

                    {/* Attachments */}
                    {model && (
                        <OrderAttachments
                            orderId={model.id}
                            isReadOnly={model.isReadOnly}
                        />
                    )}

                    {/* Products */}
                    <PurchaseOrderProductList order={model} />

                    <PurchaseOrderDetailModalFooter model={model} />

                    {showCloneModal && (
                        <PurchaseOrderCloneModal
                            order={model}
                            close={() => setShowCloneModal(false)}
                        />
                    )}

                    {showEditModal && (
                        <PurchaseOrderEditModal
                            order={model}
                            close={() => setShowEditModal(false)}
                        />
                    )}
                </>
            )}
        </MyModal>
    );
}

function SupplierOrders({ purchaseOrderId }: { purchaseOrderId: number }) {
    const { detailPath } = useOrderPresentation();

    const query = purchasesApi.useManufacturerOrdersQuery(purchaseOrderId);
    const supplierOrders = query.data;

    return (
        <>
            {supplierOrders?.map((order, i) => (
                <SupplierOrderSummarySection
                    key={order.id}
                    order={order}
                    detailPath={`${detailPath}/${purchaseOrderId}/supplier-order/${order.id}?`}
                    autoMargin={i > 0}
                />
            ))}
        </>
    );
}

// TODO: Show link to quote somewhere?
// function LinkToQuote({ model }: { model: PurchaseOrderDetail }) {
//     if (model.context.parentOrder?.isQuote) {
//         // Purchase orders link to quote if it came from a quote
//         // No link is shown if it came from a draft order
//         return (
//             <Link
//                 className="Link PurchaseOrderDetail__StatusBadges__OrderLink"
//                 to={`/quotes/${model.context.parentOrder.id}`}
//             >
//                 View quote
//             </Link>
//         );
//     }
//     return null;
// }
