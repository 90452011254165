import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import * as BiIcons from 'react-icons/bi';
import { FaBarcode, FaGripLines } from 'react-icons/fa';
import { FaBuildingUser } from 'react-icons/fa6';
import { IoIosBackspace } from 'react-icons/io';
import { IoClose, IoWarningOutline } from 'react-icons/io5';
import * as MdIcons from 'react-icons/md';
import { RiKanbanView2, RiProgress5Line, RiTableView } from 'react-icons/ri';
import { SiMockserviceworker } from 'react-icons/si';

const Icons = {
    Archive: BiIcons.BiSolidTrashAlt,
    Backspace: IoIosBackspace,
    Barcode: FaBarcode,
    Bundle: BiIcons.BiSolidDiscount,
    Calendar: BiIcons.BiCalendar,
    CaretDown: AiFillCaretDown,
    CaretUp: AiFillCaretUp,
    Check: BiIcons.BiCheck,
    CheckPartial: MdIcons.MdSquare,
    ChevronLeft: BiIcons.BiChevronLeft,
    ChevronDown: BiIcons.BiChevronDown,
    ChevronRight: BiIcons.BiChevronRight,
    Clear: BiIcons.BiSolidXCircle,
    Clone: BiIcons.BiDuplicate,
    Close: IoClose,
    Copy: BiIcons.BiSolidCopy,
    CreditCard: BiIcons.BiSolidCreditCard,
    Dashboard: MdIcons.MdOutlineDashboard,
    Documents: BiIcons.BiFolder,
    Dollar: BiIcons.BiDollar,
    Download: BiIcons.BiSolidDownload,
    DragHandle: FaGripLines,
    Edit: BiIcons.BiSolidPencil,
    Employees: MdIcons.MdFolderShared,
    Error: BiIcons.BiSolidErrorCircle,
    Export: BiIcons.BiSolidCloudDownload,
    ExternalLink: BiIcons.BiLinkExternal,
    File: BiIcons.BiFile,
    Filter: BiIcons.BiFilterAlt,
    History: BiIcons.BiHistory,
    Home: BiIcons.BiSolidHome,
    Info: BiIcons.BiSolidInfoCircle,
    KanbanView: RiKanbanView2,
    List: BiIcons.BiListUl,
    Location: BiIcons.BiSolidMap,
    Locked: BiIcons.BiLockAlt,
    Logout: BiIcons.BiLogOut,
    MenuDots: BiIcons.BiDotsVerticalRounded,
    MenuLines: BiIcons.BiMenu,
    Minus: BiIcons.BiMinus,
    Mock: SiMockserviceworker,
    Numpad: BiIcons.BiCalculator,
    Package: BiIcons.BiSolidPackage,
    Percent: MdIcons.MdOutlinePercent,
    Person: BiIcons.BiUser,
    Play: BiIcons.BiPlay,
    Plus: BiIcons.BiPlus,
    Print: BiIcons.BiSolidPrinter,
    PurchaseOrder: MdIcons.MdLabelImportant,
    Refresh: BiIcons.BiRefresh,
    Reports: MdIcons.MdTimeline,
    Quote: MdIcons.MdSell,
    SadFace: BiIcons.BiSad,
    Search: BiIcons.BiSearch,
    Settings: MdIcons.MdSettings,
    Supplier: FaBuildingUser,
    TableView: RiTableView,
    Tasks: MdIcons.MdTask,
    Undo: BiIcons.BiUndo,
    UpdateAvailable: BiIcons.BiMessageSquareError,
    Upload: BiIcons.BiUpload,
    Warning: IoWarningOutline,
    WorkflowStatusNew: BiIcons.BiLoaderCircle,
    WorkflowStatusQuoting: BiIcons.BiRightArrow,
    WorkflowStatusInProgress: RiProgress5Line,
    WorkflowStatusClosed: BiIcons.BiSolidCheckCircle,
    WorkflowStatusCancelled: BiIcons.BiBlock,
} as const;

export default Icons;
