import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import WorkflowPhase from 'features/deals/enums/WorkflowPhase';
import { Deal } from 'features/deals/models/Deal';
import { WorkflowDetail } from 'features/deals/models/WorkflowDetail';
import { WorkflowStatus } from 'features/deals/models/WorkflowStatus';
import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import coalesceClassNames from 'utils/coalesceClassNames';
import DealFlagPill from '../DealFlagPill/DealFlagPill';
import WorkflowStatusIcon from '../WorkflowStatusIcon/WorkflowStatusIcon';
import './DealsTable.scss';

export default function DealsTable({
    workflow,
    data,
    isLoading,
    isError,
    isFetching,
}: {
    workflow: WorkflowDetail;
    data?: Deal[];
    isLoading: boolean;
    isError: boolean;
    isFetching: boolean;
}) {
    const statusMap = useMemo(
        () =>
            workflow.context.statuses.reduce((map, status) => {
                map.set(status.id, status);
                return map;
            }, new Map<string, WorkflowStatus>()),
        [workflow.context.statuses],
    );

    const statusDisplayOrder = workflow.context.statuses.reduce((map, status, index) => {
        map[status.id] = index;
        return map;
    }, {} as Record<string, number>);

    const columns = useMemo(() => {
        const cols = ColumnBuilder<Deal>()
            .column({
                key: 'tuid',
                label: 'ID',
                whiteSpace: 'nowrap',
                isSortable: true,
                getValue: deal => deal.tuid,
            })
            .column({
                key: 'status',
                label: 'Status',
                isSortable: true,
                defaultSort: 'ASC',
                // use display order for value so sorting by this column gives the same order as kanban
                getValue: deal => statusDisplayOrder[deal.statusId],
                renderValue: (val, deal) => {
                    const status = statusMap.get(deal.statusId);
                    return (
                        <div className="DealsTable__StatusCell">
                            <WorkflowStatusIcon status={status} />
                            {status?.name}
                        </div>
                    );
                },
            })
            .column({
                key: 'customer',
                label: 'Customer',
                isSortable: true,
                getValue: deal => deal.customerName,
            })
            .column({
                key: 'description',
                label: 'Description',
                isSortable: true,
                getValue: deal => deal.description,
            })
            .column({
                key: 'quoteCount',
                label: 'Quotes',
                align: 'center',
                isSortable: true,
                getValue: deal => deal.context.quoteCount,
                renderValue: (val, deal) => (
                    <div
                        className={coalesceClassNames(
                            'DealsTable__CountCell',
                            getQuoteCountDisplayType(val, statusMap.get(deal.statusId)?.phase),
                        )}
                    >
                        {deal.context.quoteCount}
                    </div>
                ),
            })
            .column({
                key: 'orderCount',
                label: 'Orders',
                align: 'center',
                isSortable: true,
                getValue: deal => deal.context.orderCount,
                renderValue: (val, deal) => (
                    <div
                        className={coalesceClassNames(
                            'DealsTable__CountCell',
                            getOrderCountDisplayType(val, statusMap.get(deal.statusId)?.phase),
                        )}
                    >
                        {deal.context.orderCount}
                    </div>
                ),
            });

        // Add a column for each flag
        workflow.context.flags.forEach(flag => {
            cols.column({
                key: `flag-${flag.id}`,
                label: flag.name,
                isSortable: true,
                getValue: deal => {
                    // use index of the selected value so sorting works out nicely
                    const dealFlag = deal.context.flagValues.find(
                        fv => fv.workflowFlagId === flag.id,
                    );
                    const index = flag.values.findIndex(
                        v => v.id === dealFlag?.workflowFlagValueId,
                    );
                    return index;
                },
                renderValue: index => {
                    const val = flag.values[index];
                    return val ? (
                        <DealFlagPill
                            value={val}
                            size="small"
                        />
                    ) : null;
                },
            });
        });

        return cols.build();
    }, [statusDisplayOrder, statusMap, workflow.context.flags]);

    const [urlParams] = useSearchParams();

    return (
        <DataTable
            className="DealsTable"
            columns={columns}
            data={data}
            rowLinkTo={deal => `${deal.id}?${urlParams}`}
            isLoading={isLoading}
            isError={isError}
            isRefreshing={isFetching}
        />
    );
}

function getQuoteCountDisplayType(
    quoteCount: number,
    phase?: WorkflowPhase,
): 'warning' | 'active' | 'default' {
    if (quoteCount === 0) {
        if (phase === WorkflowPhase.Quoting || phase === WorkflowPhase.InProgress) {
            return 'warning';
        }
    }
    if (quoteCount > 0) {
        if (phase === WorkflowPhase.New) {
            return 'warning';
        }
        if (phase === WorkflowPhase.Quoting) {
            return 'active';
        }
    }
    return 'default';
}

function getOrderCountDisplayType(
    orderCount: number,
    phase?: WorkflowPhase,
): 'warning' | 'active' | 'default' {
    if (orderCount === 0) {
        if (phase === WorkflowPhase.InProgress || phase === WorkflowPhase.Closed) {
            return 'warning';
        }
    }
    if (orderCount > 0) {
        if (phase === WorkflowPhase.New || phase === WorkflowPhase.Quoting) {
            return 'warning';
        }
        if (phase === WorkflowPhase.InProgress) {
            return 'active';
        }
    }
    return 'default';
}
