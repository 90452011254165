import { Manufacturer } from '../models/Manufacturer';

export const fakeManufacturers: Manufacturer[] = [
    {
        id: 1,
        name: 'Manufacturer One',
        logo: '',
        email: 'fakemanufacturer1@email.com',
        timezone: 'Australia/Adelaide',
        customer_settings: {
            default_shipping_method_id: null,
        },
        shipping_methods: [
            {
                id: 1,
                name: 'Standard',
                address: null,
            },
            {
                id: 2,
                name: 'Express',
                address: null,
            },
            {
                id: 3,
                name: 'Pick up',
                address: '123 Pickup st, Pickupville',
            },
        ],
        brands: [],
        manufacturer_customer_settings: {
            is_internal: true,
        },
    },
    {
        id: 2,
        name: 'Another Manufacturer',
        logo: '',
        email: 'anothermanufacturer@email.com',
        timezone: 'Australia/Adelaide',
        customer_settings: {
            default_shipping_method_id: 2,
        },
        shipping_methods: [
            {
                id: 1,
                name: 'Regulat post',
                address: null,
            },
            {
                id: 2,
                name: 'Express post',
                address: null,
            },
            {
                id: 3,
                name: 'Click&Collect',
                address: 'The spot where you collect things',
            },
        ],
        brands: [],
        manufacturer_customer_settings: {
            is_internal: false,
        },
    },
];
