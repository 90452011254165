import { createTheme } from '@mui/material/styles';
import palette from './palette.module.scss';

const Theme = createTheme({
    typography: {
        fontFamily: '"Inter", sans-serif',
        fontWeightLight: 100,
        fontWeightRegular: 400,
        fontWeightBold: 700,
        button: { fontWeight: 700 },
        fontSize: 14,
    },
    transitions: {
        duration: {},
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true, // turn off default btn shadows
            },
            styleOverrides: {
                root: {
                    lineHeight: '1.5',
                    padding: '16px 21px 16px',
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(0,0,0,0.25)',
                },
                invisible: {
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    lineHeight: '22px',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    minWidth: '120px',
                },
                notchedOutline: {
                    borderColor: 'rgba(0,0,0,0.2)',
                },
                sizeSmall: {
                    height: '40px',
                },
                multiline: {
                    height: 'auto',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    minWidth: '150px',
                    paddingRight: '0',
                },
                input: {
                    height: 'auto',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '14px',
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    padding: 0,
                    width: '40px',
                    height: '40px',
                    '&.Mui-selected': {
                        backgroundColor: palette.primaryBase,
                        color: 'white',
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: palette.primaryBase,
                        color: 'white',
                    },
                },
            },
        },
    },
    palette: {
        background: {
            default: undefined,
        },
        primary: {
            main: palette.primaryBase,
            light: '#e5f3fb',
            dark: '#004c6b',
        },
        secondary: {
            main: palette.secondaryDark,
            light: '#a5b2ba',
            dark: palette.secondaryDark,
        },
        error: {
            main: palette.dangerBase,
            light: palette.dangerLight,
            dark: palette.dangerLight,
        },
    },
});

export default Theme;
