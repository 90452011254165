import Icons from 'Icons';
import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import DataTableFilters, { FilterBuilder } from 'components/DataTableFilters/DataTableFilters';
import MyButton from 'components/MyButton/MyButton';
import OrderProductAddModal from 'features/orders/components/OrderProductAddModal/OrderProductAddModal';
import { ProductDimensionDisplay } from 'features/orders/components/ProductDimensionDisplay/ProductDimensionDisplay';
import { OrderLineErrorDisplay } from 'features/orders/enums/OrderLineError';
import { useWindowGroupOptions } from 'features/orders/helpers/orderProductHelper';
import QuoteStatus from 'features/quotes/enums/QuoteStatus';
import { QuoteDetail } from 'features/quotes/models/QuoteDetail';
import { QuoteOrderLine } from 'features/quotes/models/QuoteOrderLine';
import { useBreakpoints } from 'providers/Breakpoints';
import React, { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { formatCurrency } from 'utils/helpers';
import './QuoteProductList.scss';

const COLUMNS = ColumnBuilder<QuoteOrderLine>()
    .column({
        key: 'product',
        label: 'Product',
        getValue: item => <ProductDisplay orderLine={item} />,
    })
    .column({
        key: 'windowGroupName',
        label: 'Window or group',
        getValue: item => item.windowGroupName,
    })
    .column({
        key: 'dimensions',
        label: 'Dimensions',
        whiteSpace: 'nowrap',
        getValue: item => <ProductDimensionDisplay orderLine={item} />,
    })
    .column({
        key: 'quantity',
        label: 'Qty',
        align: 'center',
        getValue: item => item.quantity,
    })
    .column({
        key: 'total',
        label: 'Total',
        getValue: item => formatCurrency(item.sellPrice),
    })
    .column({
        key: 'options',
        label: 'Options',
        getValue: item => <OptionsDisplay orderLine={item} />,
        maxWidth: '400px',
    })
    .column({
        key: 'notes',
        label: 'Notes',
        getValue: item => item.notes,
        maxWidth: '400px',
    })
    .build();

export default function QuoteProductList({ order }: { order: QuoteDetail }) {
    const [filteredProducts, setFilteredProducts] = useState<QuoteOrderLine[]>();
    const [showAddProductModal, setShowAddProductModal] = useState(false);

    const breakpoints = useBreakpoints();

    const windowGroupOptions = useWindowGroupOptions(order.context.orderLines);

    const filters = useMemo(() => {
        return FilterBuilder<QuoteOrderLine>()
            .filter({
                label: 'Search',
                type: 'search',
                getFields: item => {
                    const result = [
                        item.context.product.name,
                        item.context.product.context.brandName,
                        item.windowGroupName,
                    ];
                    return result;
                },
            })
            .filter(
                order.status === QuoteStatus.Draft && {
                    label: 'Filter',
                    type: 'select',
                    options: [
                        {
                            label: 'All products',
                            value: 'all',
                        },
                        {
                            label: 'With errors',
                            value: 'problems',
                        },
                    ],
                    defaultValue: 'all',
                    allowBlank: false,
                    // this isn't used, because we are overriding applyFilter below
                    getField: () => '',
                    applyFilter: (f, value, item) => {
                        if (value === 'problems') {
                            return item.context.errors.length > 0;
                        }
                        return true;
                    },
                },
            )
            .filter({
                label: 'Window or group',
                type: 'select',
                getField: item => item.windowGroupName,
                options: [
                    { label: '(None)', value: '__NO_LABEL', isPlaceholder: true },
                    ...windowGroupOptions,
                ],
                defaultValue: '',
                allowBlank: true,
                applyFilter: (f, value, item: QuoteOrderLine) => {
                    if (value === '__NO_LABEL') {
                        return !item.windowGroupName;
                    }
                    return item.windowGroupName?.startsWith(value) ?? false;
                },
            })
            .build();
    }, [windowGroupOptions, order.status]);

    const [urlParams] = useSearchParams();

    return (
        <div className="QuoteProductList">
            {order.context.orderLines.length === 0 ? (
                <div className="QuoteProductList__Empty">
                    {order.isReadOnly ? (
                        <label>No products added</label>
                    ) : (
                        <>
                            <label>No products added yet</label>
                            <MyButton
                                label="Add product"
                                IconLeft={Icons.Plus}
                                buttonType="Accent"
                                onClick={() => setShowAddProductModal(true)}
                            />
                        </>
                    )}
                </div>
            ) : (
                <>
                    <div className="QuoteProductList__Actions">
                        {!order.isReadOnly && (
                            <MyButton
                                className="QuoteProductList__Toolbar__AddButton"
                                label="Add product"
                                IconLeft={Icons.Plus}
                                buttonType="Accent"
                                onClick={() => setShowAddProductModal(true)}
                                fullWidth={breakpoints.isVerySmallOnly}
                            />
                        )}
                    </div>

                    <div className="QuoteProductList__Toolbar">
                        <DataTableFilters
                            filters={filters}
                            data={order.context.orderLines}
                            onChange={setFilteredProducts}
                        />
                    </div>
                    {(filteredProducts?.length ?? 0) === 0 ? (
                        <div className="QuoteProductList__ProductsFilteredOut">
                            No products in this order matched your search.
                        </div>
                    ) : (
                        <div className="QuoteProductList__Products">
                            <DataTable
                                columns={COLUMNS}
                                data={filteredProducts}
                                zebra="dark"
                                rowLinkTo={item => `products/${item.id}?${urlParams}`}
                                showHeader="narrow"
                            />
                        </div>
                    )}
                </>
            )}

            {showAddProductModal && (
                <OrderProductAddModal
                    order={order}
                    close={() => setShowAddProductModal(false)}
                />
            )}
        </div>
    );
}

function OptionsDisplay({ orderLine }: { orderLine: QuoteOrderLine }) {
    const optionsString = useMemo(
        () =>
            orderLine.context.configuration.optionGroups
                .map(og => og.options)
                .map(options => options[options.length - 1]?.value)
                .join(', '),
        [orderLine.context.configuration.optionGroups],
    );
    return (
        <div className="QuoteProductList__OptionsDisplay">
            {optionsString && (
                <div className="QuoteProductList__OptionsDisplay__Options">{optionsString}</div>
            )}
            {orderLine.context.errors.map((err, index) => (
                <div
                    key={index}
                    className="QuoteProductList__OptionsDisplay__Errors"
                >
                    {OrderLineErrorDisplay.display(err)}
                </div>
            ))}
        </div>
    );
}

function ProductDisplay({ orderLine }: { orderLine: QuoteOrderLine }) {
    return (
        <div className="QuoteProductList__ProductDisplay">
            <div className="QuoteProductList__ProductDisplay__Brand">
                {orderLine.context.product.context.brandName}
            </div>
            <div>{orderLine.context.product.name}</div>
        </div>
    );
}
