import StatusPill, { StatusPillSize } from 'components/StatusPill/StatusPill';
import PurchaseOrderStatus, {
    PurchaseOrderStatusDisplay,
} from 'features/purchases/enums/PurchaseOrderStatus';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './PurchaseOrderStatusBadge.scss';

export default function PurchaseOrderStatusBadge({
    status,
    size = 'medium',
}: {
    status?: PurchaseOrderStatus;
    size?: StatusPillSize;
}) {
    return status ? (
        <StatusPill
            className={coalesceClassNames('PurchaseOrderStatusBadge', status)}
            size={size}
        >
            {PurchaseOrderStatusDisplay.display(status)}
        </StatusPill>
    ) : null;
}
