import ArchivedBadge from 'components/ArchivedBadge/ArchivedBadge';
import DataTable, {
    ColumnBuilder,
    DataTableColumn,
    DataTableSortDirection,
} from 'components/DataTable/DataTable';
import DataTablePaging from 'components/DataTable/DataTablePaging';
import DataTableCriteria, { CriteriaBuilder } from 'components/DataTableCriteria/DataTableCriteria';
import useSalesRepOptions from 'features/orders/helpers/useSalesRepOptions';
import PurchaseOrderStatus, {
    PurchaseOrderStatusDisplay,
} from 'features/purchases/enums/PurchaseOrderStatus';
import { PurchaseOrder } from 'features/purchases/models/PurchaseOrder';
import purchasesApi, { PurchaseOrderListParams } from 'features/purchases/purchases.api';
import { selectIsMultiSupplier, selectManufacturers } from 'features/settings/settings.slice';
import { useDataTableDynamicQuery } from 'hooks/useDataTableDynamicQuery';
import React, { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { formatDateTimeRelative } from 'utils/dateHelpers';
import { formatCurrency } from 'utils/helpers';
import { ManufacturerOrderStatusBadge } from '../ManufacturerOrderStatusBadge/ManufacturerOrderStatusBadge';
import PurchaseOrderStatusBadge from '../PurchaseOrderStatusBadge/PurchaseOrderStatusBadge';
import './PurchaseOrdersTable.scss';

export default function PurchaseOrdersTable() {
    const [urlParams] = useSearchParams();
    const manufacturers = useAppSelector(selectManufacturers);
    const isMultiSupplier = useAppSelector(selectIsMultiSupplier);

    const supplierOptions = useMemo(
        () => manufacturers?.map(m => ({ label: m.name, value: m.id.toString() })) ?? [],
        [manufacturers],
    );
    const salesRepOptions = useSalesRepOptions({ includeNotSet: true });

    const criteriaFields = useMemo(
        () =>
            CriteriaBuilder<PurchaseOrderListParams>()
                .criteria({
                    field: 'search',
                    label: 'Search',
                    type: 'search',
                    defaultValue: '',
                    urlParam: 'search',
                })
                .criteria({
                    field: 'status',
                    label: 'Status',
                    type: 'select',
                    options: PurchaseOrderStatusDisplay.options,
                    defaultValue: '',
                    allowBlank: true,
                    urlParam: 'status',
                })

                .criteria(
                    isMultiSupplier && {
                        field: 'manufacturer_id',
                        label: 'Supplier',
                        type: 'select',
                        options: supplierOptions,
                        defaultValue: '',
                        allowBlank: true,
                        urlParam: 'manufacturer',
                    },
                )
                .criteria({
                    field: 'sales_rep_override',
                    label: 'Sales rep',
                    type: 'autocomplete',
                    options: salesRepOptions,
                    defaultValue: '',
                    urlParam: 'rep',
                })
                .criteria({
                    field: 'date',
                    label: 'Date',
                    type: 'date',
                    range: 'past',
                    blankValue: 'all',
                    defaultValue: '-30',
                    urlParam: 'date',
                })
                .criteria({
                    field: 'archived',
                    label: 'Archived',
                    type: 'toggle',
                    defaultValue: 'false',
                    urlParam: 'archived',
                })
                .build(),
        [isMultiSupplier, salesRepOptions, supplierOptions],
    );

    const { queryParams, setQueryCriteria, setQuerySort, paging, setQueryPaging } =
        useDataTableDynamicQuery(criteriaFields);

    const query = purchasesApi.usePurchaseOrderListQuery(queryParams);

    const handleSortChanged = useCallback(
        (col: DataTableColumn<PurchaseOrder>, direction: DataTableSortDirection) => {
            setQuerySort({
                propertyKey: col.key,
                direction,
            });
        },
        [setQuerySort],
    );

    const columns = useMemo(
        () =>
            ColumnBuilder<PurchaseOrder>()
                .column({
                    label: 'Order',
                    key: 'tuid',
                    getValue: item => item.tuid,
                })
                .column({
                    label: 'Status',
                    key: 'status',
                    render: item => <StatusCell item={item} />,
                })
                .column({
                    label: 'Supplier status',
                    key: 'supplierStatus',
                    render: item => <SupplierStatusCell item={item} />,
                })
                .column(
                    isMultiSupplier && {
                        label: 'Supplier',
                        key: 'supplier',
                        getValue: item =>
                            manufacturers?.find(m => item.manufacturerId === m.id)?.name,
                    },
                )
                .column({
                    label: 'Sidemark',
                    key: 'sidemark',
                    getValue: item => item.sidemark,
                })

                .column({
                    label: 'Sales rep',
                    key: 'salesRep',
                    render: item => item.salesRep,
                })
                .column({
                    label: 'Products',
                    key: 'products',
                    align: 'center',
                    getValue: item => (item.totalQuantity > 0 ? item.totalQuantity : null),
                })
                .column({
                    label: 'Total',
                    key: 'total',
                    getValue: (item: PurchaseOrder) =>
                        item.totalCost > 0 && formatCurrency(item.totalCost),
                })
                .column({
                    label: 'Date created',
                    key: 'createdAt',
                    render: item => formatDateTimeRelative(item.createdAt),
                })

                .build(),
        [isMultiSupplier, manufacturers],
    );

    return (
        <div className="PurchasesTable">
            <div className="PurchasesTable__FilterBar">
                <DataTableCriteria
                    fields={criteriaFields}
                    onChange={setQueryCriteria}
                    onRefresh={query.refetch}
                    isRefreshing={query.isFetching}
                />
            </div>

            <DataTable
                className="PurchasesTable__DataTable"
                isLoading={query.isLoading}
                isError={query.isError}
                data={query.data?.data}
                rowLinkTo={item => `${item.id}?${urlParams}`}
                zebra="light"
                useStickyHeader={true}
                useFrontEndSorting={false}
                onSortChanged={handleSortChanged}
                columns={columns}
                isRefreshing={query.isFetching}
            />

            {(query.data?.total ?? 0) > 0 && (
                <DataTablePaging
                    data={paging}
                    totalCount={query.data?.total}
                    onChange={setQueryPaging}
                />
            )}
        </div>
    );
}

function StatusCell({ item }: { item: PurchaseOrder }) {
    return (
        <div className="PurchasesTable__StatusCell">
            <PurchaseOrderStatusBadge
                status={item.status}
                size="small"
            />

            {item.isArchived && <ArchivedBadge size="small" />}
        </div>
    );
}

function SupplierStatusCell({ item }: { item: PurchaseOrder }) {
    if (item.status !== PurchaseOrderStatus.Submitted) {
        return null;
    }
    return (
        <div className="PurchasesTable__SupplierStatusCell">
            {item.context.manufacturerOrders?.map(mfOrder => (
                <ManufacturerOrderStatusBadge
                    key={mfOrder.id}
                    status={mfOrder.context.orderStatus}
                />
            ))}
        </div>
    );
}
