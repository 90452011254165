import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

export type DealsListViewType = 'kanban' | 'table';

type DealsState = {
    listViewType: DealsListViewType;
};

const initialState: DealsState = {
    listViewType: localStorage.getItem('dealsListViewType') === 'table' ? 'table' : 'kanban',
};

const slice = createSlice({
    name: 'deals',
    initialState,
    reducers: {
        setDealsListViewType: (state, action: PayloadAction<DealsListViewType>) => {
            state.listViewType = action.payload;
            localStorage.setItem('dealsListViewType', action.payload);
        },
    },
});

export const { setDealsListViewType } = slice.actions;

export default slice.reducer;

export const selectDealsListViewType = (state: RootState) => state.deals.listViewType;
