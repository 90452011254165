import { CircularProgress } from '@mui/material';
import {
    HistoryState,
    ManufacturerOrderHistoryEntry,
} from 'features/purchases/models/ManufacturerOrderHistoryEntry';
import purchasesApi from 'features/purchases/purchases.api';
import React, { useMemo } from 'react';
import { formatDateTimeRelative } from 'utils/dateHelpers';
import { ManufacturerOrderStatusBadge } from '../ManufacturerOrderStatusBadge/ManufacturerOrderStatusBadge';
import './ManufacturerOrderHistoryDisplay.scss';

const historyFieldLabels: Partial<Record<keyof HistoryState, string>> = {
    customer_notes: 'Notes',
    eta: 'ETA',
    order_status_id: 'Status',
    tracking_information: 'Tracking information',
    total_freight_override: 'Total freight override',
};

export default function ManufacturerOrderHistoryDisplay({
    item,
    manufacturerId,
}: {
    item: ManufacturerOrderHistoryEntry;
    manufacturerId: number;
}) {
    const diffFields = useMemo(
        () =>
            Object.entries(item.new)
                .filter(([k, value]) => {
                    const key = k as keyof HistoryState;
                    const oldVal = item.old[key];
                    return oldVal !== value && !!historyFieldLabels[key];
                })
                .map(([key, value]) => ({ key: key as keyof HistoryState, value })),
        [item.new, item.old],
    );

    return (
        <div className="ManufacturerOrderHistoryDisplay">
            <div className="ManufacturerOrderHistoryDisplay__Title">
                <span className="ManufacturerOrderHistoryDisplay__UpdatedBy">
                    Updated by <strong>{item.manufacturer_employees_full_name}</strong>
                </span>
                <span className="ManufacturerOrderHistoryDisplay__Timestamp">
                    {formatDateTimeRelative(item.created_at)}
                </span>
            </div>
            {diffFields.length > 0 && (
                <div className="ManufacturerOrderHistoryDisplay__Changes">
                    {diffFields.map(field => (
                        <FieldDetail
                            key={field.key}
                            field={field}
                            manufacturerId={manufacturerId}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

function FieldDetail({
    field,
    manufacturerId,
}: {
    field: { key: keyof HistoryState; value: string | number | null };
    manufacturerId: number;
}) {
    return (
        <div className="ManufacturerOrderHistoryDisplay__FieldDetail">
            <span className="ManufacturerOrderHistoryDisplay__FieldDetail__Field">
                {historyFieldLabels[field.key]}
            </span>

            <div className="ManufacturerOrderHistoryDisplay__FieldDetail__Content">
                {field.key === 'order_status_id' ? (
                    <StatusLabel
                        statusId={`${field.value}`}
                        manufacturerId={manufacturerId}
                    />
                ) : (
                    field.value ?? <em>Removed</em>
                )}
            </div>
        </div>
    );
}

function StatusLabel({
    statusId,
    manufacturerId,
}: {
    statusId: string | number;
    manufacturerId: number;
}) {
    const query = purchasesApi.useManufacturerOrderStatusesQuery(manufacturerId);
    const status = query.data?.find(s => `${s.id}` === `${statusId}`);

    return query.isLoading ? (
        <CircularProgress size={28} />
    ) : status ? (
        <ManufacturerOrderStatusBadge status={status} />
    ) : (
        <>{statusId}</>
    );
}
